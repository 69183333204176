import { useCallback, useMemo } from 'react';
import { uniq, omit, pickBy, concat } from 'ramda';
import { CATEGORIES_BY_SLUG, CATEGORY_SLUGS, parameterize } from '@moda/portal-stanchions';
import { AvailabilityInput } from '../generated/types';
import { SortOption, DEFAULT_SORT_OPTIONS, PRODUCTS_PER_PAGE } from '../constants';
import { useCountry } from '../components/CountrySelector';
import { useUrlParams, UrlParams } from './useUrlParams';
import { useCategoryPath } from './useCategoryPath';
import { usePLPScoring } from './usePLPScoring';

export type PLPParams = {
  attributes: string[];
  availability: AvailabilityInput[];
  category?: string;
  colors: string[];
  saletags: string[];
  designerSlug?: string;
  designers: string[];
  guideSlug?: string;
  page: number;
  pricemax?: number;
  pricemin?: number;
  q?: string;
  sale?: boolean;
  section?: string;
  sizes: string[];
  sort: string;
  subcategory?: string;
  vertical?: string;
  versionId?: number;
};

export type PLPParamKey = keyof PLPParams;

const toArray = (item?: string | string[]) => (Array.isArray(item) ? item : item ? [item] : []);

const toString = (item?: string | string[]) => (Array.isArray(item) ? item[0] : item);

const toNumber = (item?: string | string[]) => {
  const string = toString(item);
  return isNaN(Number(string)) ? undefined : Number(string);
};

const toBoolean = (item?: string | string[]) =>
  Array.isArray(item) ? item[0] === 'true' : item === 'true';

const parseParams = (params: UrlParams, sortOptions: SortOption[]): PLPParams => ({
  attributes: toArray(params.attributes),
  availability: toArray(params.availability)
    .map(value => value.replace(/-/g, '_'))
    .filter(value =>
      Object.values(AvailabilityInput).includes(value as AvailabilityInput)
    ) as AvailabilityInput[],
  category: toString(params.category),
  colors: toArray(params.colors).map(color => color.replace(/-/g, '/')),
  saletags: toArray(params.saletags),
  designerSlug: toString(params.designerSlug),
  designers: toArray(params.designers),
  guideSlug: toString(params.guideSlug),
  page: isNaN(Number(params.page)) ? 1 : Number(params.page),
  pricemax: toNumber(params.pricemax),
  pricemin: toNumber(params.pricemin),
  q: toString(params.q),
  sale: toBoolean(params.sale),
  section: toString(params.section),
  sizes: toArray(params.sizes).map(value => {
    const [category, ...size] = value.split('-');
    return `${CATEGORIES_BY_SLUG[category]}:${size.map(value => value.toUpperCase()).join(' ')}`;
  }),
  sort: toString(params.sort) || sortOptions[0].value,
  subcategory: toString(params.subcategory),
  versionId: toNumber(params.version_id),
  vertical: toString(params.vertical)
});

const parameterizeParams = (params: PLPParams, sortOptions: SortOption[]): UrlParams =>
  pickBy(value => value != null, {
    ...params,
    availability: params.availability.map(value => parameterize(value)),
    colors: params.colors.map(color => color.replace(/\//g, '-')),
    saletags: params.saletags.map(saletag => parameterize(saletag)),
    page: !params.page || params.page === 1 ? undefined : params.page.toString(),
    pricemax: params.pricemax?.toString(),
    pricemin: params.pricemin?.toString(),
    sale: params.sale ? 'true' : undefined,
    sizes: params.sizes.map(value => {
      const [category, size] = value.split(':');
      return `${CATEGORY_SLUGS[category]}-${size.replace(/\s/g, '-').toLowerCase()}`;
    }),
    sort: params.sort === sortOptions[0].value ? undefined : parameterize(params.sort)
  });

export const FILTER_KEYS: PLPParamKey[] = [
  'attributes',
  'availability',
  'colors',
  'saletags',
  'designers',
  'page',
  'pricemax',
  'pricemin',
  'sale',
  'sizes'
];

export const usePLPParams = (sortOptions: SortOption[] = DEFAULT_SORT_OPTIONS) => {
  const { params: urlParams, setParams: setUrlParams } = useUrlParams();
  const categoryPath = useCategoryPath();
  const { country } = useCountry();

  const params = useMemo(() => parseParams(urlParams, sortOptions), [urlParams, sortOptions]);

  const numberOfProductsPerPage = PRODUCTS_PER_PAGE;
  const scoring = usePLPScoring();

  const setParams = useCallback(
    (params: PLPParams) =>
      setUrlParams({
        ...omit(Object.keys(params), urlParams),
        ...parameterizeParams(params, sortOptions)
      }),
    [urlParams, setUrlParams, sortOptions]
  );

  const currentSort = useMemo(
    () => sortOptions.find(option => option.value === params.sort) || sortOptions[0],
    [sortOptions, params.sort]
  );

  const queryVariables = useMemo(
    () => ({
      after: btoa(
        JSON.stringify({
          page: params.page - 1,
          first: numberOfProductsPerPage,
          sort: currentSort.value
        })
      ),
      attributes: params.attributes,
      availability: params.availability,
      categoryPath,
      countryCode: country.alpha2Code,
      designers: params.designerSlug ? [params.designerSlug] : params.designers,
      dynamicReRanking: true,
      filterColors: params.colors,
      saleTags: params.saletags,
      section: params.section,
      priceRange: {
        min: params.pricemin ?? null,
        max: params.pricemax ?? null
      },
      onSale: params.sale,
      sizes: params.sizes,
      q: params.q,
      slug: params.guideSlug || params.designerSlug || '',
      scoring,
      ...(params.versionId && { versionId: params.versionId })
    }),
    [params, categoryPath, country, numberOfProductsPerPage, scoring, currentSort]
  );

  const changePriceFilter = useCallback(
    (min: string, max: string) =>
      setParams({
        ...params,
        page: 1,
        pricemin: min ? Number(min) : undefined,
        pricemax: max ? Number(max) : undefined
      }),
    [params, setParams]
  );

  const addFilter = useCallback(
    (key: PLPParamKey, value: string) => {
      const currentValue = params[key];
      const newValue = Array.isArray(currentValue) ? uniq([...currentValue, value]) : value;
      setParams({ ...params, page: 1, [key]: newValue });
    },
    [params, setParams]
  );

  const removeFilter = useCallback(
    (key: PLPParamKey, value: string) => {
      const currentValue = params[key];
      const newValue = Array.isArray(currentValue)
        ? currentValue.filter(item => item !== value)
        : undefined;
      setParams({ ...params, page: 1, [key]: newValue });
    },
    [params, setParams]
  );

  const addFilterList = useCallback(
    (key: PLPParamKey, value: string[]) => {
      const currentValue = params[key];
      const newValue = Array.isArray(currentValue)
        ? uniq(concat(currentValue, value))
        : uniq([currentValue, ...value]);
      setParams({ ...params, page: 1, [key]: newValue });
    },
    [params, setParams]
  );

  const removeFilterList = useCallback(
    (key: PLPParamKey, value: string[]) => {
      const currentValue = params[key];
      const newValue = Array.isArray(currentValue)
        ? currentValue.filter(item => !value.includes(item))
        : [currentValue].filter(item => item && !value.includes(item.toString()));
      setParams({ ...params, page: 1, [key]: newValue });
    },
    [params, setParams]
  );

  const clearFilter = useCallback(
    (key: PLPParamKey | PLPParamKey[]) => {
      const keys = Array.isArray(key) ? key : [key];
      const newParams = keys.reduce((result, key) => {
        const currentValue = params[key];
        return {
          ...result,
          [key]: Array.isArray(currentValue) ? [] : undefined
        };
      }, params);
      setParams({ ...newParams, page: 1 });
    },
    [params, setParams]
  );

  const clearAllFilters = useCallback(() => clearFilter(FILTER_KEYS), [clearFilter]);

  const replaceFilter = useCallback(
    (key: PLPParamKey, value: string) => {
      setParams({ ...params, [key]: value, page: 1 });
    },
    [params, setParams]
  );

  const goToPage = useCallback((page: number) => addFilter('page', String(page)), [addFilter]);

  const changeSort = useCallback((sort: string) => addFilter('sort', sort), [addFilter]);

  return {
    params,
    queryVariables,
    currentSort,
    changePriceFilter,
    addFilter,
    replaceFilter,
    removeFilter,
    addFilterList,
    removeFilterList,
    clearFilter,
    clearAllFilters,
    goToPage,
    changeSort
  };
};
